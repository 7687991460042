<template>
  <AModalContent class="god-ape-generated-modal">
    <div class="god-ape-generated-modal-content">
      <div class="god-ape-generated-modal-title">Congrats on your new Ape!</div>
      <div class="god-ape-generated-modal-name">{{ name }}</div>
      <img class="god-ape-generated-modal-image" :src="image" alt="teen" />
      <div class="god-ape-generated-modal-transaction">
        Transaction:
        <a target="_blank" :href="txLink" @click.stop>{{ txShort }}</a>
      </div>

      <a href="#" class="god-ape-generated-modal-button" @click="hide">Close</a>
    </div>
  </AModalContent>
</template>

<script>
import { shorten } from "../../helpers/utils";
import { Config } from "../../config";
import ITwitter from "../../assets/icons/twitter.svg?inline";
import AModalContent from "../Modal/AModalContent.vue";
export default {
  components: { ITwitter, AModalContent },
  props: {
    // image:
    //   "https://storage.mint.bullsandapesproject.com/teens/images/TeenBull.06762.png",
    // name: "Teen Bull #9",
    // tx: "0x8fb5925d82c1d9e99a793ab74e5e67f029f79bdfb74b28eb9b8f0d2dd2a16c83",
    // type: "teen",
    metadata: Object,
    team: String,
  },
  data() {},
  computed: {
    txLink() {
      return `${Config.network.scan}/tx/${this.tx}`;
    },
    name() {
      return this.metadata.name;
    },
    image() {
      return this.metadata.image.replace("original", "w=500");
    },
    tx() {
      return this.metadata.tx;
    },
    txShort() {
      return shorten(this.tx, 10, 8);
    },
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 100);
  },

  methods: {
    hide() {
      this.$emit("close");
    },
    init() {
      // Image preload
      const img = new Image();

      img.onload = () => {};
      img.src = this.image;
    },
  },
};
</script>

<style lang="scss">
.god-ape-generated-modal {
  width: 100vw;
  height: 100vh;
  &-skip {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background-color: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(3px);
    color: #fff;
    text-transform: uppercase;
    border-radius: 5px;
    border: 1px solid #fff;
    padding: 5px 12px;
    box-sizing: border-box;
    z-index: 10;
    text-decoration: none;
    font-size: 20px;
  }
  &-video {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 700px;
    text-align: center;
    padding: 25px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      /* height: 80%; */
      border-radius: 10px;
      object-fit: contain;
      flex: 1;
      min-height: 0;
    }
  }
  &-title {
    font-size: 24px;
    color: var(--primary);
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &-name {
    font-size: 20px;
    color: #fff;
    font-weight: bold;
    margin-bottom: 15px;
  }
  &-transaction {
    margin-top: 15px;
  }
  &-transaction a {
    color: var(--primary);
  }
  &-share {
    margin-top: 8px;
    display: flex;
    align-items: center;
    // min-width: 280px;
    &-title {
      margin-right: 5px;
      font-size: 16px;
      // color: rgba(255,255,255, 0.5);
      // text-transform: uppercase;
    }
    &-items {
      display: flex;
      align-items: center;
      a {
        width: 25px;
        color: rgba(255, 255, 255, 0.5);
        transition: 0.2s;
        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  &-button {
    display: inline-block;
    margin-top: 15px;
    padding: 10px;
    width: 150px;
    text-transform: uppercase;
    font-weight: bold;
    background-color: var(--primary);
    color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      filter: brightness(0.8);
    }
  }
}

.t-generated-modal-video-enter-from,
.t-generated-modal-video-leave-to {
  opacity: 0;
}
.t-generated-modal-video-leave-active {
  background-color: #fff;
}
.t-generated-modal-video-enter-active,
.t-generated-modal-video-leave-active {
  transition: opacity 1s;
}
</style>
